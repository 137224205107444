import { ref } from "vue";

export interface LinkI {
  title: string,
  href: string,
}

export interface LandingMenuItemI {
  name: string,
  links: LinkI[],
  href?: string,
  isDropdown?: boolean,
}

export type ModeType =
  | 'authByLogin'
  | 'registration'
  | 'passwordRecovery'
  | 'resultMessage'

const modeAuth = ref<ModeType>('authByLogin');
const showAuthDialog = ref();
const drawer = ref(false);
const activeName = ref();

export function useLandingLogin() {

  function showDialog(m: ModeType) {
    drawer.value = false;
    modeAuth.value = m;
    showAuthDialog.value = true;
  }

  return {
    modeAuth,
    showAuthDialog,
    drawer,
    activeName,
    showDialog,
  };
}
